import * as React from "react"
import {
  StyledImageContainer,
  StyledCaption,
  StyledCaptionText,
  StyledCaptionYear,
} from "./styled-components"

interface ImageProps {
  caption: string
  year: string
}
export default (props: ImageProps) => (
  <StyledImageContainer className={props.className}>
    <img src={props.src} alt={props.caption ? props.caption : ""} />
    <StyledCaption>
      {props.caption && <StyledCaptionText>{props.caption}</StyledCaptionText>}
      {props.year && <StyledCaptionYear>{props.year}</StyledCaptionYear>}
    </StyledCaption>
  </StyledImageContainer>
)
